<template>
  <div id="app">
    <!-- <VideoPopup /> -->
    <router-view />
  </div>
</template>

<script>
import { getId } from '@/utils/util'
import { getCookie, setCookie } from '@/storage'
// import VideoPopup from '@/components/VideoPopup'
export default {
  components: {
    // VideoPopup
  },
  created() {
    const type = getId('type') || ''
    const secretKey = getCookie('secret_key') || ''
    const newSecretKey = getId('secret_key') || ''
    if (type && newSecretKey && secretKey != newSecretKey) {
      setCookie('secret_key', newSecretKey)
      if (type == 'login') this.$store.dispatch('merchant/showLogin')
      if (type == 'register') this.$store.dispatch('merchant/showRegister')
    }
  }
}
</script>

<style lang="scss">
// 引入动画css
@import './assets/style/animate.css';
@import './assets/style/main.scss';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
